import React, { useState } from 'react'
import './Faq.css'
import Question from './Question'

import { Plus } from '../../images and icons/images/Index'

const Faq = () => {
	const [open1, setOpen1] = useState(false)
	const buttonOpen1 = () => setOpen1(!open1)

	const [open2, setOpen2] = useState(false)
	const buttonOpen2 = () => setOpen2(!open2)

	const [open3, setOpen3] = useState(false)
	const buttonOpen3 = () => setOpen3(!open3)

	const [open4, setOpen4] = useState(false)
	const buttonOpen4 = () => setOpen4(!open4)

	const [open5, setOpen5] = useState(false)
	const buttonOpen5 = () => setOpen5(!open5)

	const [open6, setOpen6] = useState(false)
	const buttonOpen6 = () => setOpen6(!open6)

	return (
		<div className={'container faq'}>
			<h2 className={'mainArticle'}>Типові запитання:</h2>
			<Question
				question={'Як записатись на пробне заняття?'}
				answer={'Для запису на пробне заняття залишайте '}
				selected1={'заявку на сайті.'}
				link={'#services'}
				img={Plus}
				open={open1}
				onClick={buttonOpen1}
			/>
			<Question
				question={'Скільки коштує одне заняття?'}
				answer={
					'Вартість заняття залежить від віку студента і пакета навчання. Актуальні ціни на навчання можна переглянути '
				}
				selected1={'тут.'}
				img={Plus}
				open={open2}
				link={'#services'}
				onClick={buttonOpen2}
			/>
			<Question
				question={'Чи можна виконати шкільне домашнє на уроці?'}
				answer={
					'Так. Шкільне домашнє можна виконати на занятті, проте обовʼязково попередьте свого вчителя наперед, щоб він вдало склав план і Ви встигли виконати домашнє та опанувати нову тему уроку.'
				}
				img={Plus}
				open={open3}
				onClick={buttonOpen3}
			/>
			<Question
				question={'Що робити, якщо я не зможу бути на уроці'}
				answer={
					'Ми цінуємо наших клієнтів, тому пропонуємо найвигідніші умови відпрацювання занять! Ви можете перенести урок на інший зручний день та час, якщо попередите вчителя за 12 годин до запланованого уроку.'
				}
				img={Plus}
				open={open4}
				onClick={buttonOpen4}
			/>
			<Question
				question={'Чи можна у Вас визначити рівень знань?'}
				answer={
					'Так, звісно! Ми БЕЗКОШТОВНО визначимо рівень знань на першому пробному уроці та складемо індивідуальну програму навчання, щоб якнайшвидше досягнути успіху!'
				}
				img={Plus}
				open={open5}
				onClick={buttonOpen5}
			/>
			<Question
				question={'Як розпочати навчання у репетиторському центрі MPLUS?'}
				answer={
					'Просто залиште заявку на БЕЗКОШТОВНИЙ пробний урок! Під час заняття вчитель познайомиться з Вами, визначить рівень знань і підбере індивідуальну програму відповідно до цілей та побажань.'
				}
				img={Plus}
				open={open6}
				onClick={buttonOpen6}
			/>
		</div>
	)
}

export default Faq
