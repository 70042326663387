import React from 'react'
import './navigation.scss'

const Navigation = () => {
	return (
		<div className='header-navigation'>
			<a href='#services' className='header-navigation__item'>
				Наші послуги
			</a>
			<a href='#schoolMPlus' className='header-navigation__item'>
				Про школу
			</a>
			<a href='#team' className='header-navigation__item'>
				Наша команда
			</a>
			<a href='#aboutUs' className='header-navigation__item'>
				Переваги
			</a>
			<a href='#feedbacks' className='header-navigation__item'>
				Відгуки
			</a>
		</div>
	)
}

export default Navigation
