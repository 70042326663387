import { motion } from 'framer-motion'
import { React, useState } from 'react'
import myVideo from '../../Video/schoolVideo.mp4'
import playBtn from '../../images and icons/icons/playBtn.svg'
import poster from '../../images and icons/images/poster.jpg'
import './SchoolMPlus.scss'

// ICONS
import hatIcon from '../../images and icons/icons/hat.svg'
import studyIcon from '../../images and icons/icons/study.svg'
import timeIcon from '../../images and icons/icons/time.svg'
import videoIcon from '../../images and icons/icons/video.svg'

export default function SchoolMPlus() {
	function playVideo() {
		let videoElement = document.getElementById('video')
		let playBtn = document.getElementById('play_button')

		if (videoElement.paused) {
			videoElement.play()
			playBtn.style.display = 'none'
		} else {
			videoElement.pause()
			playBtn.style.display = 'block'
		}
	}

	const [isHovered_zoom, setHovered_zoom] = useState(false)
	const [isHovered_time, setHovered_time] = useState(false)
	const [isHovered_lessons, setHovered_lessons] = useState(false)
	const [isHovered_type, setHovered_type] = useState(false)

	return (
		<div
			className='schoolMPlus schoolMPlus__background blockMargin'
			id='schoolMPlus'
		>
			<div className='container'>
				<motion.div
					className='schoolMPlus__title title'
					initial={{ opacity: 0, x: -15 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					transition={{
						delay: '0.2',
					}}
				>
					MPLUS CENTER
				</motion.div>
				<div className='schoolMPlus__info'>
					<div className='schoolMPlus__text textBlock'>
						<div className='textBlock__title'>
							MPLUS — це інноваційна онлайн-школа для учнів 1–11 класів та
							дорослих студентів, яка пропонує персоналізоване навчання з
							фокусом на реальні результати:
						</div>
						<div className='textBlock__text'>
							✓ Індивідуальний план навчання: кожен урок розробляється під
							конкретного учня, щоб повністю відповідати його потребам та
							інтересам.
							<br />
							✓ Платформа Optimate: у кожного студента є зручний онлайн-кабінет
							для управління уроками, оплатами та моніторингу прогресу в режимі
							реального часу.
							<br />
							✓ Постійний зворотний зв’язок: наші вчителі регулярно інформують
							про успішність та прогрес студентів, надаючи повну картину їхніх
							досягнень.
							<br />
							✓ Пробний урок — безкоштовно: перше заняття даруємо, щоб Ви змогли
							переконатися у якості навчання з MPLUS.
							<br />
							✓ Вигідні пакети занять: оплачуйте 10 уроків та отримуйте 1
							безкоштовно, або обирайте пакет з 25 уроків і отримайте 3
							додаткові заняття у подарунок.
							<br />✓ Реферальна програма: запросіть друга та отримайте
							безкоштовне заняття!
						</div>
					</div>

					<div
						id='video_container'
						className='schoolMPlus__video'
						onClick={playVideo}
					>
						<video id='video' poster={poster}>
							<source src={myVideo} type='video/mp4' />
						</video>
						<img src={playBtn} id='play_button' alt='play' />
					</div>
				</div>
				<div className='schoolMPlus__connection'>
					<div
						className='connectionBlock'
						onMouseEnter={() => {
							setHovered_zoom(true)
						}}
						onMouseLeave={() => setHovered_zoom(false)}
					>
						<motion.img
							src={videoIcon}
							className='connectionBlock__icon'
							animate={{ scale: isHovered_zoom ? 1.2 : 1 }}
							transition={{ type: 'spring', stiffness: 400, damping: 10 }}
							alt='icon'
						/>
						<div src='#' className='connectionBlock__title'>
							Zoom
						</div>
						<div src='#' className='connectionBlock__description'>
							Уроки проходять на платформі Zoom
						</div>
					</div>
					<div
						className='connectionBlock'
						onMouseEnter={() => {
							setHovered_time(true)
						}}
						onMouseLeave={() => setHovered_time(false)}
					>
						<motion.img
							src={timeIcon}
							className='connectionBlock__icon'
							animate={{ scale: isHovered_time ? 1.2 : 1 }}
							transition={{ type: 'spring', stiffness: 400, damping: 10 }}
						/>
						<div src='#' className='connectionBlock__title'>
							60 хвилин
						</div>
						<div src='#' className='connectionBlock__description'>
							Тривалість заняття
						</div>
					</div>
					<div
						className='connectionBlock'
						onMouseEnter={() => {
							setHovered_lessons(true)
						}}
						onMouseLeave={() => setHovered_lessons(false)}
					>
						<motion.img
							src={hatIcon}
							className='connectionBlock__icon'
							animate={{ scale: isHovered_lessons ? 1.2 : 1 }}
							transition={{ type: 'spring', stiffness: 400, damping: 10 }}
						/>
						<div src='#' className='connectionBlock__title'>
							2 заняття
						</div>
						<div src='#' className='connectionBlock__description'>
							на тиждень
						</div>
					</div>
					<div
						className='connectionBlock'
						onMouseEnter={() => {
							setHovered_type(true)
						}}
						onMouseLeave={() => setHovered_type(false)}
					>
						<motion.img
							src={studyIcon}
							className='connectionBlock__icon'
							animate={{ scale: isHovered_type ? 1.2 : 1 }}
							transition={{ type: 'spring', stiffness: 400, damping: 10 }}
						/>
						<div src='#' className='connectionBlock__title'>
							Індивідуальний
						</div>
						<div src='#' className='connectionBlock__description'>
							підхід до кожного учня
						</div>
					</div>
				</div>
				<div className='schoolMPlus__footer'>
					Ви можете обрати будь-який зручний час для занять, адже графік
					підбираємо індивідуально
				</div>
			</div>
		</div>
	)
}
