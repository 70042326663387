import { React, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactPhoneInput from 'react-phone-input-2'
import { useNavigate } from 'react-router-dom'
import { baseUrl, botId, encodedToken } from '../../Constants.js'
import loadingHTML from '../../UI/loading/loading.js'
import '../../UI/loading/loading.scss'

export default function ValidateMarathonInputs() {
	let disabledButton = { background: 'grey' }
	const navigate = useNavigate()

	useEffect(() => {
		let marathonForm = document.getElementById('marathonForm__form')
		let container = document.getElementsByClassName('marathonForm')[0]
		let myInput = container.getElementsByClassName('form-control')[0]
		myInput.id = 'marathonForm__input_phone'

		if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
			if (window.innerWidth > 992) {
				if (marathonForm) {
					marathonForm.classList.add('forSafari')
				}
			}
		}
	}, [])

	function sendQuestion() {
		let name = document.getElementById('marathonForm__input_name')
		let phone = document.getElementById('marathonForm__input_phone')

		// VALIDATION
		if (name.value === '') {
			name.setCustomValidity('Заповніть це поле.')
			return
		} else if (phone.value === '') {
			name.setCustomValidity('')
			phone.setCustomValidity('Заповніть це поле.')
			return
		}

		name.setCustomValidity('')
		phone.setCustomValidity('')

		SendQuestion(name.value, phone.value)
	}

	function AnimateLoading() {
		let mainDiv = document.querySelector('#marathonForm__input_loading')
		mainDiv.getElementsByClassName('check')[0].classList =
			'check check-complete success'
		;[...mainDiv.getElementsByClassName('fill')].map(el => {
			el.classList = 'fill fill-complete success'
			return null
		})
		mainDiv.getElementsByClassName('path')[0].classList = 'path path-complete'

		setTimeout(function () {
			mainDiv.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
		}, 5000)
	}

	const {
		register,
		control,
		formState: { errors, isValid },
	} = useForm({ mode: 'onBlur' })
	let redBord = { border: '2px solid red' }
	let blackBord = { border: '2px solid black' }
	function SendQuestion(name, phone) {
		let btnElement = document.getElementById('marathonForm__input_btn')
		btnElement.innerHTML = `<div className="marathonForm__input_loading" id="marathonForm__input_loading" >${loadingHTML}</div>`

		const msg = `Користувач ${name} хоче записатися на марафон англійської мови. 
Телефон: ${phone}`

		fetch(baseUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				validator: encodedToken,
				chat_id: botId,
				message: msg,
			}),
		})
			.then(response => {
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`)
				}
				return response.json()
			})
			.then(data => {
				console.log('Success:', data)
				AnimateLoading()
				navigate('/thank-you')
			})
			.catch(error => {
				console.error('Error:', error)
				btnElement.innerHTML = 'Спробуйте ще раз2..'
				setTimeout(function () {
					btnElement.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
				}, 5000)
			})
	}

	function setValidity(event) {
		if (event.currentTarget.customValidity !== '') {
			event.currentTarget.setCustomValidity('')
		}
	}
	return (
		<div className='marathonForm questionForm'>
			<form className='marathonForm__form'>
				<input
					required
					type='text'
					name='name'
					className='marathonForm__input'
					id='marathonForm__input_name'
					placeholder='Введіть ваше ім’я'
					{...register('name', {
						required: true,
						pattern: new RegExp(/^[a-zA-ZА-яіІїЇҐґєЄ]{4,25}$/),
					})}
					style={errors.name ? redBord : blackBord}
					onChange={setValidity}
				/>
				<Controller
					name='phone'
					control={control}
					rules={{
						required: true,
						valueAsNumber: true,
						pattern: new RegExp(
							/^[\]?[(]?[0-9]{3}[)]?[-\s. ]?[0-9]{3}[-\s.]?[0-9]{6,9}$/
						),
					}}
					style={errors.phone ? redBord : blackBord}
					className='test'
					render={({ field }) => (
						<ReactPhoneInput
							{...field}
							id='marathonForm__input_phone'
							country='ua'
							placeholder='Ваш номер телефону'
							inputExtraProps={{
								required: true,
								autoComplete: 'tel',
								valueAsNumber: true,
							}}
						/>
					)}
				/>

				<button
					className='marathonForm__btn'
					style={isValid ? null : disabledButton}
					disabled={!isValid}
					type='button'
					onClick={sendQuestion}
					id='marathonForm__input_btn'
				>
					ЗАЛИШИТИ ЗАЯВКУ
				</button>
			</form>
		</div>
	)
}
