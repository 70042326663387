import { motion } from 'framer-motion'
import roads from '../../images and icons/icons/marafon-icon.svg'
import number_01 from '../../images and icons/icons/number_01.svg'
import number_02 from '../../images and icons/icons/number_02.svg'
import number_03 from '../../images and icons/icons/number_03.svg'
import Form from './Form'

import './Marafon.scss'

const Marafon = () => {
	return (
		<section className='marafon'>
			<div className='container'>
				<motion.div
					className='title'
					initial={{ opacity: 0, x: 15 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					transition={{
						delay: '0.2',
					}}
				>
					Марафон англійської мови
				</motion.div>
				<div className='marafon-subtitle'>
					5-ти денний марафон з англійської мови
				</div>
				<p className='marafon-text'>
					Це формат, що дозволить дитині всього за 5 днів поспіль значно
					покращити рівень англійської мови.<br></br> Запис доступний на
					марафони двох рівнів: <b>Elementary</b> або <b>Intermediate</b>
				</p>
				<h3 className='marafon-program__title'>Програма марафону:</h3>
			</div>
			<div className='roadMap advantages__roadMap'>
				<img src={roads} className='roadMap__map' alt='path' />
				<div className='container roadMap__grid'>
					<motion.div
						className='roadMap__advantage'
						initial={{ opacity: 0, y: -40 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{
							delay: 0,
						}}
						viewport={{ once: true }}
					>
						<img className='roadMap__number' src={number_01} alt='number_01' />
						<div className='roadMap__text'>
							Дитина проходить початковий тест, щоб вчитель визначила, який саме
							марафон підійде: Elementary чи Intermediate
						</div>
					</motion.div>

					<motion.div
						className='roadMap__advantage'
						initial={{ opacity: 0, y: -40 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{
							delay: 0.1,
						}}
						viewport={{ once: true }}
					>
						<img className='roadMap__number' src={number_02} alt='number_02' />
						<div className='roadMap__text'>
							Дитина проходить 5 уроків протягом 5 днів з вчителем у форматі
							онлайн-конференції із живим спілкуванням 👩‍💻 <br></br>В кінці
							уроків вчитель задає цікаве та інтерактивне домашнє завдання 🤩
						</div>
					</motion.div>
					<motion.div
						className='roadMap__advantage'
						initial={{ opacity: 0, y: -40 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{
							delay: 0.2,
						}}
						viewport={{ once: true }}
					>
						<img className='roadMap__number' src={number_03} alt='number_03' />
						<div className='roadMap__text'>
							Дитина проходить фінальний тест по пройденому матеріалу та отримує
							сертифікат 🏆
						</div>
					</motion.div>
				</div>
			</div>
			<div className='container'>
				<div className='marafon-info'>
					<div className='marafon-info__top'>
						<p className='marafon-info__top-cost'>
							Вартість 1 марафону: 2600 грн
						</p>
						<p className='marafon-info__top-text'>
							15% знижка для клієнтів, які навчаються на інших послугах у нашому
							центрі
						</p>
					</div>
					<div className='marafon-info__content'>
						<p>
							Детальніше з програмами марафонів та темами уроків можна
							ознайомитись за{' '}
							<a href='https://telegra.ph/Marafon-z-angl%D1%96jskoi-movi-12-11-2'>
								посиланням
							</a>
						</p>
						<Form />
					</div>
				</div>
				<div className='marafon-bottom'>
					<p>
						Вам цікаво, як проходять заняття? Подивіться у наших соцмережах:
					</p>
					<ul>
						<li className='instagram-marafon'>
							<a href='https://www.instagram.com/mplus.center' target='_blank'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
								>
									<g clipPath='url(#clip0_904_932)'>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M4.35859 0.502073C3.16045 0.556133 2.12197 0.849064 1.28341 1.68408C0.44193 2.52348 0.152638 3.56608 0.0984433 4.75175C0.0647546 5.4918 -0.13224 11.0827 0.439002 12.5489C0.824223 13.538 1.58293 14.2986 2.58114 14.685C3.04692 14.8662 3.57863 14.9889 4.35859 15.0247C10.8803 15.3198 13.2978 15.1591 14.3172 12.5489C14.4981 12.0843 14.6226 11.553 14.657 10.775C14.9551 4.23659 14.6087 2.81935 13.4721 1.68408C12.5706 0.784774 11.5101 0.172597 4.35859 0.502073ZM4.41863 13.7097C3.70458 13.6775 3.31717 13.5586 3.05864 13.4585C2.40831 13.2057 1.91982 12.7192 1.66862 12.0727C1.2336 10.9586 1.37788 5.66726 1.4167 4.81105C1.45478 3.97238 1.62469 3.2059 2.21643 2.61416C2.94879 1.8836 3.89499 1.52557 10.3376 1.81633C11.1783 1.85432 11.9466 2.02387 12.5398 2.61416C13.2721 3.3447 13.6354 4.29818 13.3395 10.716C13.3073 11.4283 13.1879 11.8148 13.0876 12.0727C12.4248 13.7712 10.9 14.007 4.41863 13.7097ZM10.4086 3.86336C10.4086 4.34699 10.8019 4.74025 11.2875 4.74025C11.773 4.74025 12.167 4.34699 12.167 3.86336C12.167 3.37975 11.773 2.98683 11.2875 2.98683C10.8019 2.98683 10.4086 3.37975 10.4086 3.86336ZM3.61743 7.76301C3.61743 9.83484 5.30114 11.5146 7.37811 11.5146C9.45509 11.5146 11.1388 9.83484 11.1388 7.76301C11.1388 5.69118 9.45509 4.01251 7.37811 4.01251C5.30114 4.01251 3.61743 5.69118 3.61743 7.76301ZM4.93715 7.76301C4.93715 6.41881 6.02984 5.3282 7.37811 5.3282C8.72639 5.3282 9.81907 6.41881 9.81907 7.76301C9.81907 9.10795 8.72639 10.1989 7.37811 10.1989C6.02984 10.1989 4.93715 9.10795 4.93715 7.76301Z'
											fill='#F2E0D1'
										/>
									</g>
									<defs>
										<clipPath id='clip0_904_932'>
											<rect
												width='14.7202'
												height='14.7202'
												fill='white'
												transform='translate(0.0386047 0.407776)'
											/>
										</clipPath>
									</defs>
								</svg>
							</a>
						</li>
						<li className='tiktok-marafon'>
							<a
								href='https://www.tiktok.com/@mplus.center?_t=8jIoByoV5ld&_r=1'
								target='_blank'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='15'
									height='16'
									viewBox='0 0 15 16'
									fill='none'
								>
									<path
										d='M13.4027 3.75309C12.6021 3.57767 11.8778 3.15406 11.3338 2.54305C10.7898 1.93204 10.4541 1.16503 10.3748 0.352102V0H7.60385V10.9392C7.60441 11.4246 7.45168 11.8978 7.16728 12.2919C6.88288 12.686 6.48124 12.981 6.01925 13.135C5.55725 13.289 5.05833 13.2943 4.59315 13.1501C4.12797 13.0059 3.72012 12.7195 3.42737 12.3316C3.16509 11.9881 3.00449 11.5784 2.96372 11.1488C2.92295 10.7192 3.00365 10.2868 3.19668 9.90046C3.38971 9.51415 3.68738 9.18935 4.05602 8.9628C4.42466 8.73625 4.84957 8.61699 5.28269 8.61849C5.52223 8.61713 5.76057 8.65221 5.98948 8.72252V5.92171C5.7231 5.88884 5.45465 5.87547 5.18631 5.8817C4.19786 5.90802 3.23856 6.22095 2.42588 6.78218C1.61321 7.34341 0.982428 8.12857 0.61079 9.04153C0.239152 9.95448 0.142785 10.9556 0.333488 11.9223C0.524192 12.889 0.993689 13.7793 1.68449 14.4842C2.39247 15.2014 3.29845 15.6926 4.28732 15.8956C5.27618 16.0986 6.30329 16.0041 7.23813 15.6242C8.17298 15.2442 8.97336 14.596 9.53759 13.7618C10.1018 12.9276 10.4044 11.9451 10.4069 10.9392V5.33754C11.5245 6.13293 12.8646 6.55841 14.238 6.5539V3.83311C13.9683 3.83422 13.6991 3.8074 13.4349 3.75309H13.4027Z'
										fill='#F2E0D1'
									/>
								</svg>
							</a>
						</li>
						<li className='facebook-marafon'>
							<a
								href='https://www.facebook.com/mplus.tutoring.center'
								target='_blank'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
								>
									<g clipPath='url(#clip0_904_939)'>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M9.62022 15.132V8.50788H11.6313L11.9598 5.56383H9.62022V4.1299C9.62022 3.37181 9.63959 2.61978 10.6989 2.61978H11.7719V0.514886C11.7719 0.483237 10.8502 0.411743 9.91786 0.411743C7.9706 0.411743 6.75133 1.63146 6.75133 3.87115V5.56383H4.59967V8.50788H6.75133V15.132H9.62022Z'
											fill='#F2E0D1'
										/>
									</g>
									<defs>
										<clipPath id='clip0_904_939'>
											<rect
												width='14.7202'
												height='14.7202'
												fill='white'
												transform='translate(0.919617 0.407776)'
											/>
										</clipPath>
									</defs>
								</svg>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</section>
	)
}
export default Marafon
