import { motion } from 'framer-motion'
import './Club.scss'
import Form from './Form'

const Club = () => {
	return (
		<section className='club'>
			<div className='container'>
				<motion.div
					className='title'
					initial={{ opacity: 0, x: 15 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					transition={{
						delay: '0.2',
					}}
				>
					Розмовний клуб англійської мови
				</motion.div>
				<div className='club-box'>
					<div className='club-box__top'>
						<h4>Для студентів віком від 14 до 20 років</h4>
					</div>
					<div className='club-box__center'>
						<div className='club-box__center-item'>
							<div className='club-box__center-name'>Розклад:</div>
							<p>1 раз на тиждень </p>
							<p>
								Тривалість: 50-70 хв <br></br>(залежно від кількості учасників){' '}
							</p>
							<p>Кількість учасників: 3-10 осіб</p>
						</div>
						<div className='club-box__center-item'>
							<div className='club-box__center-name'>Вартість:</div>
							<p>Разове заняття: 170 грн </p>
							<p>
								При оплаті абонементом вартість уроку становить всього 136 грн
							</p>
						</div>
					</div>
					<div className='club-box__bottom'>
						<p>
							Щоб приєднатись до розмовного клубу, Вам потрібно пройти
							безкоштовний 10-ти хвилинний урок з нашим вчителем
						</p>
						<Form />
					</div>
				</div>
			</div>
		</section>
	)
}
export default Club
