import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactPhoneInput from 'react-phone-input-2'
import { useNavigate } from 'react-router-dom'
import { baseUrl, botId, encodedToken } from '../../Constants.js'
import loadingHTML from '../../UI/loading/loading.js'
import '../../UI/loading/loading.scss'

export default function ValidateMarathonInputs() {
	let disabledButton = { background: 'grey' }
	const navigate = useNavigate()
	useEffect(() => {
		let container = document.getElementsByClassName('clubForm')[0]
		let myInput = container.getElementsByClassName('form-control')[0]
		myInput.id = 'clubForm__input_phone'

		if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
			if (window.innerWidth > 992) {
				document.getElementById('clubForm__form').classList.add('forSafari')
			}
		}
	}, [])

	const {
		register,
		control,
		formState: { errors, isValid },
		getValues,
	} = useForm({ mode: 'onBlur' })
	let redBord = { border: '2px solid red' }
	let blackBord = { border: '2px solid black' }

	function sendQuestion() {
		const { name, phone } = getValues()

		if (!name) {
			const nameInput = document.getElementById('clubForm__input_name')
			nameInput.setCustomValidity('Заповніть це поле.')
			nameInput.reportValidity()
			return
		} else if (!phone) {
			const phoneInput = document.querySelector(
				'.clubForm__input_phone .form-control'
			)
			phoneInput.setCustomValidity('Заповніть це поле.')
			phoneInput.reportValidity()
			return
		}

		SendQuestion(name, phone)
	}

	function AnimateLoading() {
		let mainDiv = document.querySelector('#clubForm__input_loading')
		mainDiv.getElementsByClassName('check')[0].classList =
			'check check-complete success'
		;[...mainDiv.getElementsByClassName('fill')].map(el => {
			el.classList = 'fill fill-complete success'
			return null
		})
		mainDiv.getElementsByClassName('path')[0].classList = 'path path-complete'

		setTimeout(function () {
			mainDiv.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
		}, 5000)
	}

	function SendQuestion(name, phone) {
		let btnElement = document.getElementById('clubForm__input_btn')
		btnElement.innerHTML = `<div className="clubForm__input_loading" id="clubForm__input_loading">${loadingHTML}</div>`

		const msg = `Користувач ${name} хоче вступити у розмовний клуб. 
Телефон: ${phone}`

		fetch(baseUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				validator: encodedToken,
				chat_id: botId,
				message: msg,
			}),
		})
			.then(response => {
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`)
				}
				return response.json()
			})
			.then(data => {
				console.log('Success:', data)
				AnimateLoading()
				navigate('/thank-you')
			})
			.catch(error => {
				console.error('Error:', error)
				btnElement.innerHTML = 'Спробуйте ще раз..'
				setTimeout(function () {
					btnElement.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
				}, 5000)
			})
	}

	function setValidity(event) {
		if (event.currentTarget.customValidity !== '') {
			event.currentTarget.setCustomValidity('')
		}
	}

	return (
		<div className='clubForm questionForm'>
			<form className='clubForm__form' id='clubForm__form'>
				<input
					required
					type='text'
					name='name'
					className='clubForm__input'
					id='clubForm__input_name'
					placeholder='Введіть ваше ім’я'
					{...register('name', {
						required: true,
						pattern: /^[a-zA-ZА-яіІїЇҐґєЄ]{4,25}$/,
					})}
					style={errors.name ? redBord : blackBord}
					onChange={setValidity}
				/>
				<Controller
					name='phone'
					control={control}
					rules={{
						required: true,
						pattern: /^[\]?[(]?[0-9]{3}[)]?[-\s. ]?[0-9]{3}[-\s.]?[0-9]{6,9}$/,
					}}
					render={({ field }) => (
						<ReactPhoneInput
							{...field}
							className='clubForm__input_phone'
							country='ua'
							placeholder='Ваш номер телефону'
							inputExtraProps={{
								required: true,
								autoComplete: 'tel',
								valueAsNumber: true,
							}}
							style={errors.phone ? redBord : blackBord}
						/>
					)}
				/>

				<button
					className='clubForm__btn'
					style={isValid ? null : disabledButton}
					disabled={!isValid}
					type='button'
					onClick={sendQuestion}
					id='clubForm__input_btn'
				>
					ЗАЛИШИТИ ЗАЯВКУ
				</button>
			</form>
		</div>
	)
}
