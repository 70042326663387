import React, { useState } from 'react'
import cross from '../../../images and icons/icons/cross.svg'
import '../../Vacancies/Popup/Popup.scss'
import './Popup.scss'

export default function Popup({ sendInfo, onClose }) {
	const [formData, setFormData] = useState({
		name: '',
		phone: '',
		email: '',
	})

	// Функція для оновлення стану при введенні даних
	function handleInputChange(event) {
		const { id, value } = event.target
		setFormData(prevData => ({
			...prevData,
			[id.split('_')[1]]: value, // Наприклад: `services_name` -> `name`
		}))
	}

	// Перевірка валідності телефону
	function checkPhone(phone) {
		return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
			phone
		)
	}

	function checkEmail(email) {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
	}
	function hidePopup() {
		document.getElementById('services__popup').style.display = 'none'
		document.getElementById('overlay').style.display = 'none'
		document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
	}
	function sendInformation() {
		const { name, phone, email } = formData

		if (!name || !phone || !email) {
			alert('Будь ласка, заповніть усі поля')
			return
		}

		if (!checkPhone(phone)) {
			alert('Будь ласка, введіть коректний номер телефону')
			return
		}

		if (!checkEmail(email)) {
			alert('Будь ласка, введіть коректний email')
			return
		}

		sendInfo(formData)

		hidePopup()
	}

	return (
		<div className='popup container' id='services__popup'>
			<div className='popup__titleContainer'>
				<div className='popup__title'>Залишайте заявку</div>
				<img
					src={cross}
					alt='exit'
					className='popup__cross'
					onClick={hidePopup}
				/>
			</div>

			<div className='popup__subTitle'>На навчання в школі MPLUS</div>

			<form className='popup__formContainer formContainer'>
				<input
					className='formContainer__input'
					id='services_name'
					required
					placeholder="Введіть ім'я"
					value={formData.name}
					onChange={handleInputChange} // Оновлення стану
				/>
				<input
					className='formContainer__input'
					id='services_email'
					required
					placeholder='E-mail'
					type='email'
					value={formData.email}
					onChange={handleInputChange} // Оновлення стану
				/>
				<input
					className='formContainer__input'
					id='services_phone'
					required
					placeholder='Номер телефону'
					type='phone'
					value={formData.phone}
					onChange={handleInputChange} // Оновлення стану
				/>

				<div
					className='vacancies__btn'
					id='vacancies__input_button'
					onClick={e => {
						e.preventDefault()
						sendInformation()
					}}
				>
					ЗАЛИШИТИ ЗАЯВКУ
				</div>
			</form>
		</div>
	)
}
