import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { baseUrl, botId, encodedToken } from '../../Constants.js'
import Popup from './Popup/Popup'
import './ServicesSelection.scss'

const ServiceSelection = () => {
	const [selectedService, setSelectedService] = useState(null)
	const [selectedSubject, setSelectedSubject] = useState(null)
	const [selectedLesson, setSelectedLesson] = useState(null)
	const [prices, setPrices] = useState(null)
	const navigate = useNavigate()
	const [open, setOpen] = useState(false)

	const services = {
		school: {
			name: 'Для школярів',
			subjects: ['Математика', 'Англійська мова', 'Українська мова'],
			prices: {
				individual: '360 грн/заняття',
				'10+1': '3 600 грн: купуй 10 занять та отримуй 1 у подарунок',
				'25+3': '9 000 грн: купуй 25 занять та отримуй 3 у подарунок',
			},
		},
		nmt: {
			name: 'Підготовка до НМТ',
			subjects: ['Математика', 'Англійська мова', 'Українська мова'],
			prices: {
				individual: '420 грн/заняття',
				'10+1': '4 200 грн: купуй 10 занять та отримуй 1 у подарунок',
				'25+3': '10 500 грн: купуй 25 занять та отримуй 3 у подарунок',
			},
		},
		adults: {
			name: 'Для дорослих',
			subjects: ['Англійська мова'],
			prices: {
				individual: '480 грн/заняття',
				'10+1': '4 800 грн: купуй 10 занять та отримуй 1 у подарунок',
				'25+3': '12 000 грн: купуй 25 занять та отримуй 3 у подарунок',
			},
		},
	}
	function AnimateLoading() {
		let mainDiv = document.getElementById('vacancies__input_loading')
		mainDiv.getElementsByClassName('check')[0].classList =
			'check check-complete success'
		;[...mainDiv.getElementsByClassName('fill')].map(el => {
			el.classList = 'fill fill-complete success'
			return null
		})
		mainDiv.getElementsByClassName('path')[0].classList = 'path path-complete'

		setTimeout(function () {
			mainDiv.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
		}, 5000)

		return null
	}
	const handleServiceClick = service => {
		setSelectedService(service)
		setSelectedSubject(null)
		setSelectedLesson(null)
		setPrices(null)
	}

	const handleSubjectClick = subject => {
		setSelectedSubject(subject)
		setSelectedLesson(null)
		setPrices(services[selectedService].prices)
	}

	const handleLessonClick = lesson => {
		setSelectedLesson(lesson)
	}

	function sendInfo(data) {
		const msg = `
Нова заявка на заняття:
Ім'я: ${data.name}
Тип послуги: ${services[selectedService]?.name}
Предмет: ${selectedSubject}
Формат навчання: ${
			selectedLesson === 'individual'
				? 'Індивідуальне заняття'
				: selectedLesson === '10+1'
				? 'Пакет "10+1"'
				: 'Пакет "25+3"'
		}
Ціна: ${prices[selectedLesson]}
Контактні дані:
Пошта: ${data.email}
Телефон: ${data.phone}
		`

		let btnElement = document.getElementById('vacancies__input_button')
		btnElement.innerHTML = `<div id='vacancies__input_loading'>Надсилається</div>`

		fetch(baseUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				validator: encodedToken,
				chat_id: botId,
				message: msg,
			}),
		})
			.then(response => response.json())
			.then(data => {
				console.log('Відповідь сервера:', data)
				// AnimateLoading()
				navigate('/thank-you')
			})
			.catch(error => {
				btnElement.innerHTML = 'Спробуйте ще раз..'
				setTimeout(function () {
					btnElement.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ'
				}, 5000)
			})
	}

	function openPopup() {
		document.getElementById('overlay').style.display = 'block'
		document.getElementsByTagName('body')[0].style.overflowY = 'hidden'

		if (window.innerWidth < 1400) {
			document.getElementById('services__popup').style.display = 'block'
		} else {
			document.getElementById('services__popup').style.display = 'flex'
		}
	}
	return (
		<div className='services container blockMargin' id='services'>
			<h2 className='title'>НАШІ ПОСЛУГИ</h2>
			<div className='services-grid'>
				{/* Перша колонка */}
				<div className='service-type services-grid__block'>
					<h3>Оберіть тип послуги:</h3>
					<div className='services-grid__flex'>
						{Object.keys(services).map(serviceKey => (
							<button
								key={serviceKey}
								className={`btn ${
									selectedService === serviceKey ? 'active' : ''
								}`}
								onClick={() => handleServiceClick(serviceKey)}
							>
								{services[serviceKey].name}
							</button>
						))}
					</div>
				</div>

				{/* Друга колонка */}
				<div className='subject-selection services-grid__block'>
					<h3>Оберіть предмет:</h3>
					<div className='services-grid__flex'>
						{['Математика', 'Англійська мова', 'Українська мова'].map(
							subject => (
								<button
									key={subject}
									className={`btn ${
										selectedSubject === subject ? 'active' : ''
									}`}
									onClick={() => handleSubjectClick(subject)}
									disabled={
										!selectedService ||
										(selectedService === 'adults' &&
											subject !== 'Англійська мова')
									}
								>
									{subject}
								</button>
							)
						)}
					</div>
				</div>

				{/* Третя колонка */}
				<div className='lesson-selection services-grid__block'>
					<h3>Оберіть заняття:</h3>
					<div className='services-grid__flex'>
						{['individual', '10+1', '25+3'].map(lessonKey => (
							<div key={lessonKey} className='lesson-item'>
								<button
									className={`btn ${
										selectedLesson === lessonKey ? 'active' : ''
									}`}
									onClick={() => handleLessonClick(lessonKey)}
									disabled={!prices}
								>
									{lessonKey === 'individual'
										? 'Індивідуальне заняття'
										: lessonKey === '10+1'
										? 'Пакет "10+1"'
										: 'Пакет "25+3"'}
									{prices && <div className='price'>{prices[lessonKey]}</div>}
								</button>
							</div>
						))}
					</div>
				</div>
			</div>
			{/* Кнопка для заявки */}
			<button
				className='btn submit services-selection__btn'
				disabled={!selectedLesson}
				onClick={openPopup}
			>
				ЗАПИСАТИСЬ НА ПРОБНЕ БЕЗКОШТОВНЕ ЗАНЯТТЯ
			</button>
			<Popup sendInfo={sendInfo} />
		</div>
	)
}

export default ServiceSelection
